import NotificationMixin from './NotificationMixin';
import { formatDateTime } from '../utils/dates';

export default {
  mixins: [NotificationMixin],
  data() {
    return {
      createMessage: null,
      createRoute: null,
    };
  },
  methods: {
    formatDateTime,
    onCreated(item) {
      if (this.createMessage) {
        this.showMessage(this.createMessage);
      } else {
        this.showMessage(`${item['@id']} created`);
      }
      if (this.createRoute !== false) {
        if (this.createRoute === null) {
          let params = { id: item['@id'] }
          let defaultRoute = `${this.$options.servicePrefix}Update`;

          switch (this.$options.servicePrefix) {
            case 'TenantSeminarSetting':
            case 'TenantAccountSetting':
            case 'TenantCompanySetting':
              params = {
                tenantId: this.$route.params.id,
                id: item['@id']
              }
              break;
            case 'Issue':
              defaultRoute = `IssueShow`;
              break;
            case 'Customer':
              defaultRoute = `CustomerShow`
              break;
            default:
              break;
          }

          this.createRoute = {
            name: defaultRoute,
            params
          };
        }
        this.$router
          .push(this.createRoute)
          .catch(() => {
          });
      }
      if (this.afterCreate) {
        this.afterCreate();
      }
    },
    onSendForm() {
      const createForm = this.$refs.createForm;
      createForm.$v.$touch();
      if (!createForm.$v.$invalid) {
        this.create(createForm.$v.item.$model);
      }
    },
    resetForm() {
      this.$refs.createForm.$v.$reset();
      let itemSubs = null;
      if (typeof this.getItemSubs === 'function') {
        itemSubs = this.getItemSubs();
      }
      this.item = { ...itemSubs };
    }
  },
  watch: {
    created(created) {
      if (!created) {
        return;
      }
      this.onCreated(created);
    },

    error(message) {
      message && this.showError(message);
    }
  }
};

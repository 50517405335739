<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm" />
    <IssueForm
      :errors="violations"
      :values="item"
      :uploadAttachment="uploadAttachment"
      :deleteAttachment="deleteAttachment"
      refForm="createForm"
      ref="createForm"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import IssueForm from "../../components/issue/Form";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import CreateMixin from "../../mixins/CreateMixin";
import DocumentMixin from "../../mixins/DocumentMixin";

const servicePrefix = "Issue";

const { mapFields } = createHelpers({
  getterType: "issue/getField",
  mutationType: "issue/updateField",
});

export default {
  name: "IssueCreate",
  servicePrefix,
  mixins: [CreateMixin, DocumentMixin],
  components: {
    Loading,
    Toolbar,
    IssueForm,
  },
  data() {
    return {
      item: {
        assignedTo: null,
        attachments: [],
        description: null,
        end: null,
        start: null,
        priority: null,
        status: null,
        title: null,
      },
      confirmAttachmentDeletionMessage: this.$t("confirmAttachmentDeletion"),
    };
  },
  computed: {
    ...mapFields(["error", "isLoading", "created", "deleted", "violations"]),
  },
  methods: {
    ...mapActions("issue", {
      create: "create",
    }),
    uploadAttachment() {
      if (this.$refs.createForm.attachment) {
        this.uploadDocument(this.$refs.createForm.attachment, true);
      }
    },
    deleteAttachment(attachment) {
      this.$confirm(this.confirmAttachmentDeletionMessage, {
        buttonTrueText: this.$t('Yes, delete now'),
        buttonFalseText: this.$t('No'),
      }).then((response) => {
        if(!response){
          return;
        }
        this.deleteDocument({ "@id": attachment.iri });
        this.item.attachments.splice(
          this.item.attachments[
            this.item.attachments.findIndex(
              (el) => el["iri"] === attachment.iri
            )
          ],
          1
        );
      });
    },
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.item.attachments.push({
        name: this.uploadedDocument.name,
        iri: this.uploadedDocument["@id"],
        contentUrl: this.uploadedDocument.contentUrl,
      });
      this.$refs.createForm.attachment = null;
    },
  },
};
</script>
